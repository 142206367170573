import { getuser } from './serve'
(function (w, d, s, i, v, j, b) {
  w[i] =
    w[i] ||
    function () {
      (w[i].v = w[i].v || []).push(arguments);
    };
  (j = d.createElement(s)), (b = d.getElementsByTagName(s)[0]);
  j.async = true;
  j.charset = "UTF-8";
  j.src = "https://www.v5kf.com/183448/2cc98030355ac/v5kf.js";
  b.parentNode.insertBefore(j, b);
  setTimeout(() => {
    window.kefuButton = document.getElementById("v5btn");
  }, 1000);
})(window, document, "script", "V5CHAT"); /*  */
getuser().then((res)=>{
  if (res.status==='0'){
    let usemsg = {}
    usemsg = res.data
    V5CHAT('openId', usemsg.id);
    V5CHAT('avatar', usemsg.imgUrl);
    V5CHAT('nickname', usemsg.customerInfo.name);
    V5CHAT('metadata', [
      {key: '客户名称', val:usemsg.companyInfo.name },
      {key: '平台角色', val: '服务需求方'},
      {key: '大区', val: usemsg.companyInfo.salesRegionName},
      {key: '省区', val: usemsg.companyInfo.salesDepartmentName},
      {key: '片区', val: usemsg.companyInfo.salesAreaName},
      {key: '区域', val: usemsg.companyInfo.cityCode},
      {key: '经销商编号', val: usemsg.customerInfo.code},
      {key: '电话', val: usemsg.phone},
]);
  }
}).catch((err)=>{
  return err
})
