import request from "../request/request";

// 获取前端类目树父节点集合
//用户信息
const getuser = async () =>
  request({
    url: `/account/getInfo`,
    method: "get",
  });

export { getuser };
