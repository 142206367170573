/*
 * @Autor: syq
 * @Date: 2021-07-05 16:10:34
 */

import axios from "axios";
import { Toast } from "vant";
// import router from "../router/index";

const request = axios.create({
  baseURL: "/marketing/h5",
  headers: {
    "x-app-id": 10000,
    // "sso-sessionid": 1423199076417015809_1_1_1_1_1_1,
  },
});

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    if (localStorage.getItem("sessionId")) {
      config.headers["sso-sessionid"] = localStorage
        .getItem("sessionId")
        .split("=")[1];
    }
    // config.headers["sso-sessionid"] = "1423199783274680321_1_1_1";
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    // if (response.data.status !== "0") {
    //   router.push({ path: "/error" });
    //   return false;
    // }

    return response.data;
  },

  function (error) {
    if(location.href.includes('yvlan')){
      return
    }
    if (error.response.status === 401) {
      Toast("请先登录");
    }

    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default request;
