/*
 * @Autor: syq
 * @Date: 2021-07-09 17:31:23
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Root",
    redirect: "/home",
    component: () => import("../views/root/index.vue"),
    children: [
      {
        path: "/home", //首页
        name: "Home",
        meta: {
          title: "生态商城",
        },
        component: () => import("../views/home/index.vue"),
      },
      {
        path: "/yvlan",
        name: "Premission",
        meta: {
          title: "装修预览",
        },
        component: () => import("../views/yvlan/index.vue"),
      },
      {
        path: "/yvlanstore", // 专题
        name: "yvlanstore",
        meta: {
          title: "店铺预览",
        },
        component: () => import("../views/yvlanstore"),
      },
      {
        path: "/stores", //店铺
        name: "Stores",
        meta: {
          title: "店铺",
        },
        component: () => import("../views/stores/index.vue"),
      },
      {
        path: "/mine", //个人中心
        name: "Mine",
        meta: {
          title: "个人中心",
        },
        component: () => import("../views/mine/index.vue"),
      },
      {
        path: "/market", //购物车
        name: "Market",
        meta: {
          title: "购物车",
        },
        component: () => import("../views/market/index.vue"),
      },
      {
        path: "/classification", //分类
        name: "Classification",
        meta: {
          title: "分类",
        },
        component: () => import("../views/classification/index.vue"),
      },
    ],
  },
  {
    path: "/logistics", // 物流查询
    name: "Logistics",
    meta: {
      title: "发货信息查询",
    },
    component: () => import("../views/logistics/index.vue"),
  },
  {
    path: "/logisticsinformation", // 物流查询
    name: "Logisticsinformation",
    meta: {
      title: "发货信息查询",
    },
    component: () => import("../views/logisticsinformation/index.vue"),
  },
  {
    path: "/theme", // 专题
    name: "Theme",
    meta: {
      title: "频道页",
    },
    component: () => import("../views/theme/index.vue"),
  },
  {
    path: "/message", //客服
    name: "Message",
    meta: {
      title: "系统通知",
    },
    component: () => import("../views/message/index.vue"),
  },
  {
    path: "/shopdetails", //商品详情页
    name: "Shopdetails",
    meta: {
      title: "商品详情页",
    },
    component: () => import("../views/shopdetails/index.vue"),
  },
  {
    path: "/storehome",
    name: "Storehome",
    meta: {
      title: "单个店铺",
    },
    component: () => import("../views/Stroehome/root/index.vue"),
    children: [
      {
        path: "/storehome", //店铺首页
        name: "Storehome",
        meta: {
          title: "店铺首页",
        },
        component: () => import("../views/Stroehome/storehome/index.vue"),
      },
      {
        path: "/storeclassification", //店铺类目
        name: "Storeclassification",
        meta: {
          title: "店铺类目",
        },
        component: () =>
          import("../views/Stroehome/storeclassification/index.vue"),
      },
      {
        path: "/storedetails", //店铺详情
        name: "Storedetails",
        meta: {
          title: "店铺详情",
        },
        component: () => import("../views/Stroehome/storedetails/index.vue"),
      },
    ],
  },
  {
    path: "/storetheme", //店铺商品搜索列表页
    name: "Storetheme",
    meta: {
      title: "店铺商品",
    },
    component: () => import("../views/Stroehome/storetheme/index.vue"),
  },
  {
    path: "/storesearch", //店铺商品搜索
    name: "Storesearch",
    meta: {
      title: "搜索",
    },
    component: () => import("../views/Stroehome/storesearch/index.vue"),
  },

  {
    path: "/aftermarket", //申请售后
    name: "Aftermarket",
    component: () => import("../views/aftermarket/aftermarket.vue"),
  },
  {
    path: "/mineorder", //我的订单
    name: "Mineorder",
    meta: {
      title: "我的订单",
    },
    component: () => import("../views/mineorder/index.vue"),
  },
  {
    path: "/orderlist", //订单列表
    name: "Orderlist",
    component: () => import("../views/mineorder/components/orderlist.vue"),
  },
  {
    path: "/orderdetails", //订单详情
    name: "Orderdetails",
    meta: {
      title: "订单详情",
    },
    component: () => import("../views/orderdetails/index.vue"),
  },
  {
    path: "/balance", //我的余额
    name: "Balance",
    meta: {
      title: "我的余额",
    },
    component: () => import("../views/minebalance/balance.vue"),
  },
  {
    path: "/consume", //消费明细
    name: "Consume",
    component: () => import("../views/minebalance/consume.vue"),
  },
  // {
  //   path: "/coupon", //优惠卷
  //   name: "Coupon",
  //   component: () => import("../views/minecoupon/coupon.vue"),
  // },

  {
    path: "/address", //我的地址
    name: "Address",
    meta: {
      title: "我的地址",
    },
    component: () => import("../views/mineaddress/address.vue"),
  },
  {
    path: "/address1", //我的地址(立即购买)
    name: "Address1",
    meta: {
      title: "我的地址",
    },
    component: () => import("../views/mineaddress/address1.vue"),
  },
  {
    path: "/addressAdd",
    name: "AddressAdd",
    component: () => import("../views/mineaddress/addressAdd.vue"),
  },
  {
    path: "/addressEdit",
    name: "AddressEdit",
    component: () => import("../views/mineaddress/addressAdd.vue"),
  },
  {
    path: "/minemessage", //我的信息
    name: "Minemessage",
    meta: {
      title: "我的信息",
    },
    component: () => import("../views/minemessage/minemessage.vue"),
  },
  {
    path: "/information", //我的信息修改
    name: "Information",
    component: () => import("../views/minemessage/information.vue"),
  },
  {
    path: "/error",
    name: "Error",
    meta: {
      title: "错误提示",
    },
    component: () => import("../views/error/index.vue"),
  },
  {
    path: "/searchbox",
    name: "Searchbox",
    meta: {
      title: "搜索",
    },
    component: () => import("../views/search/searchbox.vue"),
  },
  {
    path: "/productlist",
    name: "Productlist",
    component: () => import("../views/search/productlist.vue"),
  },
  {
    path: "/placeorder",
    name: "Placeorder",
    meta: {
      title: "下单",
    },
    component: () => import("../views/placeorder/placeorder.vue"),
  },
  {
    path: "/placeorder1",
    name: "Placeorder1",
    meta: {
      title: "下单",
    },
    component: () => import("../views/placeorder/placeorder1.vue"),
  },
  {
    path: "/pay",
    name: "Pay",
    meta: {
      title: "支付页",
    },
    component: () => import("../views/pay/index.vue"),
  },
  {
    path: "/collect-orders",
    name: "Collect",
    component: () => import("../views/collect-orders/index.vue"),
  },
  {
    path: "/gift",
    name: "Gift",
    meta: {
      title: "满赠商品",
    },
    component: () => import("../views/gift/index.vue"),
  },
  {
    path: "/storesearch",
    name: "Storesearch",
    meta: {
      title: "满赠商品",
    },
    component: () => import("../views/Stroehome/storesearch/index.vue"),
  },
  {
    path: "/premission",
    name: "Premission",
    meta: {
      title: "暂无权限",
    },
    component: () => import("../views/premission/index.vue"),
  },
  {
    path: "/yvlantheme", // 专题
    name: "yvlanTheme",
    meta: {
      title: "商品预览列表",
    },
    component: () => import("../views/yvlantheme"),
  },
  {
    path: "/yvlanstoretheme", // 专题
    name: "yvlanstoreTheme",
    meta: {
      title: "店铺商品预览列表",
    },
    component: () => import("../views/yvlanstoretheme"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.path == "/yvlan"|| to.path == "/yvlantheme" ||to.path == "/yvlanyvlanstoretheme"){
    return next()
  }
  const url = window.location.href;
  if (url.includes("?sessionId")) {
    localStorage.setItem("sessionId", url.split("?")[1].split("#")[0]);
    window.location.replace(window.location.host);
  }
  if (to.meta?.title) {
    document.title = `${to.meta.title}`;
  }
  next();
});

//判断是否有权限
router.beforeEach((to, from, next) => {
  if(to.path == "/yvlan" || to.path == "/yvlantheme" ||to.path == "/yvlanstoretheme"||to.path == "/yvlanstore"){
    return next();
  }
  const seesionid = localStorage.getItem("sessionId")?.split("=")[1];
  if (
    !localStorage.getItem("ispremission") &&
    to.path !== "/premission" &&
    !seesionid
  ) {
    return next("/premission");
  }
  next();
});

router.afterEach(() => {
  var _hmt = _hmt || [];
    (function() {
      let isProduction = window.location.hostname === "yxh5.rihuayun.com"
      if (!isProduction) return;
      var hm = document.createElement("script");
      hm.src = `https://hm.baidu.com/hm.js?${isProduction ? "0b4168090abdcf67f095598e497ac4ae" : "" }`;
      var s = document.getElementsByTagName("script")[0]; 
      s.parentNode.insertBefore(hm, s);
    })();
});

export default router;
