<!--
 * @Autor: syq
 * @Date: 2021-07-09 17:31:23
-->
<template>
  <router-view />
</template>
<script>
export default {
  data() {
    return {}; 
  },
};
</script>
<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
* {
  box-sizing: border-box;
}

#v5btn {
  display: none !important;
}
</style>
