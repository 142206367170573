/*
 * @Autor: syq
 * @Date: 2021-07-09 17:31:23
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant, { Lazyload,Toast } from "vant";
import "vant/lib/index.css";
import "./utils/kefu";
import "./utils/rem";
import "./assets/font/font.css"

Vue.use(Lazyload);
Vue.use(Toast);
Vue.use(Vant);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
